// skills to display in About page
export const skills = [
    "Python",
    "JavaScript",
    "TypeScript",
    "React",
    "Node.js",
    "SQL",
    "MongoDB",
    "Java",
    "AWS",
    "HTML",
    "CSS",
    "Express",
    "Spring Boot",
    "Large Language Models (LLM)",
];
